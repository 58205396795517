<template>
  <div class="container">
    <el-upload
      class="upload-demo"
      accept=".mp4,.avi,.rmvb"
      :action="upload_qiniu_url"
      :show-file-list="false"
      :on-success="handleAvatarSuccess"
      :on-error="handleError"
      :before-upload="beforeAvatarUpload"
      :on-remove="handleRemove"
      :data="qiniuData"
    >
      <el-button size="small" type="primary">点击上传</el-button>
      <div class="el-upload__tip" slot="tip">建议视频宽高比19:9，建议时长8-45秒</div>
    </el-upload>
    <div class="video-box" v-show="url">
      <i class="el-icon-error close" @click="handleRemove"></i>
      <video class="video" controls :src="url" />
    </div>
  </div>
</template>

<script>
import { getQiniuToken } from './api'
import { nanoid } from 'nanoid'
import { Loading } from 'element-ui'
export default {
  name: 'QiniuUpload',

  data() {
    return {
      fileList: [],
      qiniuData: {
        key: '',
        token: ''
      },
      upload_qiniu_url: '',
      // 七牛云返回储存图片的子域名
      upload_qiniu_addr: '',
      imageUrl: '',
      loading: null
    }
  },

  props: {
    url: {
      type: String,
      default: ''
    }
  },

  created() {
    this.apiQiniuToken()
  },
  methods: {
    async apiQiniuToken() {
      let res = await getQiniuToken()
      this.qiniuData.token = res.token
      this.upload_qiniu_url = res.qiniu_upload
      this.upload_qiniu_addr = res.domain
    },

    // beforeAvatarUpload: function (file) {
    //   // this.apiQiniuToken()
    //   this.qiniuData.key = nanoid()
    //   // const isLt2M = file.size / 1024 / 1024 < 10
    //   // if (!isJPG && !isPNG) {
    //   //   this.$message.error('图片只能是 JPG/PNG 格式!')
    //   //   return false
    //   // }
    //   // if (!isLt2M) {
    //   //   this.$message.error('视频大小不能超过 10MB!')
    //   //   return false
    //   // }
    // },
    beforeAvatarUpload(file) {
      let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
      this.qiniuData.key = nanoid() + '.' + fileExtension
      const isLt2M = file.size / 1024 / 1024 < 50

      if (!isLt2M) {
        return this.$message.error('上传视频大小不能超过 50MB!')
      }
      this.loading = Loading.service({
        lock: true, //是否锁定屏幕的滚动
        spinner: 'el-icon-loading',
        text: '上传中', //loading下面的文字
        background: 'rgba(0, 0, 0, 0.0)', //loading的背景色
        target: 'body' //loading显示在容器
      })
      return isLt2M
    },
    handleRemove() {
      this.imageUrl = ''
      this.$emit('update:url', '')
    },
    handleAvatarSuccess(res, file, fileList) {
      if (this.loading) {
        this.loading.close()
      }

      // this.fileList = []
      this.imageUrl = this.upload_qiniu_addr + '/' + res.key
      res.uploadUrl = this.imageUrl
      res.fileName = file.name
      res.fileSize = file.size
      res.status = file.status
      // this.fileList.push({ name: file.name, url: this.imageUrl })
      this.fileList = [{ name: file.name, url: this.imageUrl }]
      // this.uploadSuccess(res)
      this.$emit('update:url', this.imageUrl)
      console.log(this.imageUrl)
    },
    handleError(res) {
      if (this.loading) {
        this.loading.close()
      }
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 400px;
  .el-upload__tip {
    margin-top: -10px;
  }
  .video-box {
    position: relative;
    width: 300px;
    height: 150px;
    .close {
      cursor: pointer;
      position: absolute;
      right: -12px;
      top: -12px;
      font-size: 20px;
    }
    .video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
