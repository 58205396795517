import { http } from '@/http/axios.js'

// 列表
export function getListAPI(params) {
  return http({
    url: `/api/store/goods/service/list`,
    method: 'get',
    params
  })
}
// detail
export function getDetailAPI(id) {
  return http({
    url: `/api/store/goods/service/detail`,
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: `/api/store/goods/service/add`,
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: `/api/store/goods/service/edit`,
    method: 'post',
    data
  })
}
// edit
export function delAPI(id) {
  return http({
    url: `/api/store/goods/service/del`,
    method: 'post',
    data: { id }
  })
}
