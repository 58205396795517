<template>
  <div class="wrapper">
    <!-- <el-form-item label="主图视频：">
      <UploadVideo :url.sync="video"></UploadVideo>
    </el-form-item> -->
    <el-form-item label="主图封面：">
      <UploadImg :url.sync="cover">
        <template slot="tip"> 建议尺寸：500像素*500像素 </template>
      </UploadImg>
    </el-form-item>
    <!-- <el-form-item label="卖点：">
      <el-input style="width: 300px" v-model="sellPoint" placeholder="请输入" size="small" clearable></el-input>
      <div class="tip">一句话简述，例如：此款商品美观大方 性价比较高 不容错过</div>
    </el-form-item> -->
    <!-- <el-form-item label="服务与承诺：">
      <GoodsService ref="service" v-model="ids"></GoodsService>
      <div style="line-height: 25px">
        <el-link style="font-size: 12px; margin-right: 10px; line-height: 25px" type="primary" :underline="false" @click="addService">去新增</el-link>
        <el-link style="font-size: 12px; line-height: 25px" type="primary" :underline="false" @click="refreshService">刷新</el-link>
      </div>
    </el-form-item> -->
    <el-form-item label="初始销量：">
      <el-input-number v-model="initial" :step="1" :min="0" size="small" step-strictly></el-input-number>
      <div class="tip">用户端展示的销量 = 初始销量 + 实际销量</div>
    </el-form-item>
    <!-- <el-divider content-position="left">积分设置</el-divider>
    <el-form-item style="margin-bottom: 0" label="积分赠送：" :rules="rules">
      <el-radio-group v-model="points_gift">
        <el-radio :label="1">开启</el-radio>
        <el-radio :label="0">关闭</el-radio>
      </el-radio-group>
      <div class="tip">开启后用户购买此商品将获得积分</div>
    </el-form-item>
    <el-form-item style="margin-bottom: 0" label="积分抵扣：" :rules="rules">
      <el-radio-group v-model="points_discount">
        <el-radio :label="1">开启</el-radio>
        <el-radio :label="0">关闭</el-radio>
      </el-radio-group>
      <div class="tip">开启后用户购买此商品可以使用积分进行抵扣</div>
    </el-form-item>
    <el-divider content-position="left">会员折扣设置</el-divider>
    <el-form-item style="margin-bottom: 0" label="会员折扣：" :rules="rules">
      <el-radio-group v-model="enable_grade">
        <el-radio :label="1">开启</el-radio>
        <el-radio :label="0">关闭</el-radio>
      </el-radio-group>
      <div class="tip">开启后会员折扣，会员购买此商品可以享受会员等级折扣价</div>
    </el-form-item>
    <el-form-item v-if="enable_grade == 1" label="会员折扣设置：" :rules="rules">
      <el-radio-group v-model="alone_grade">
        <el-radio :label="0">默认等级折扣</el-radio>
        <el-radio :label="1">单独设置折扣</el-radio>
      </el-radio-group>
      <div v-if="is_alone_grade == 0" class="tip">默认折扣：默认为用户所属会员等级的折扣率</div>
    </el-form-item>
    <el-form-item v-if="enable_grade == 1&&alone_grade==1" label="">
      <div class="level" v-for="(item, i) in grade_equity" :key="i">
        <span>{{ item.name }}</span>
        <el-input-number style="margin: 0 5px" v-model="item.equity" :precision="2" :step="0.01" :min="0" :max="9.9" size="small" step-strictly></el-input-number>
        <span>折</span>
      </div>
      <div class="tip">单独折扣：折扣率范围0.0-9.9，例如: 9.8代表98折，0代表不折扣</div>
    </el-form-item> -->
  </div>
</template>

<script>
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
import UploadVideo from '@/components/qiniu-uploader/upload-video.vue'
import GoodsService from '@/views/goods/goods-service/goods-service-mult.vue'
export default {
  name: 'BaseInfo',
  components: { UploadImg, UploadVideo, GoodsService },
  props: {
    video_url: {
      type: String,
      default: ''
    },
    video_cover_url: {
      type: String,
      default: ''
    },
    // 卖点
    selling_point: {
      type: String,
      default: ''
    },
    // 服务承诺
    serviceIds: {
      type: Array,
      default: () => []
    },
    // 初始销量
    sales_initial: {
      type: [String, Number],
      default: ''
    },
    // 积分赠送
    is_points_gift: {
      type: [String, Number],
      default: ''
    },
    // 积分抵扣
    is_points_discount: {
      type: [String, Number],
      default: ''
    },
    // 会员折扣
    is_enable_grade: {
      type: [String, Number],
      default: ''
    },
    // 会员折扣设置
    is_alone_grade: {
      type: [String, Number],
      default: ''
    },
    // 单独会员折扣配置
    level: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }]
    }
  },
  computed: {
    video: {
      get() {
        return this.video_url
      },
      set(val) {
        this.$emit('update:video_url', val)
      }
    },
    cover: {
      get() {
        return this.video_cover_url
      },
      set(val) {
        this.$emit('update:video_cover_url', val)
      }
    },
    // 卖点
    sellPoint: {
      get() {
        return this.selling_point
      },
      set(val) {
        this.$emit('update:selling_point', val)
      }
    },
    // 服务承诺
    ids: {
      get() {
        return this.serviceIds
      },
      set(val) {
        this.$emit('update:serviceIds', val)
      }
    },
    // 初始销量
    initial: {
      get() {
        return this.sales_initial
      },
      set(val) {
        this.$emit('update:sales_initial', val)
      }
    },
    // 积分赠送
    points_gift: {
      get() {
        return this.is_points_gift
      },
      set(val) {
        this.$emit('update:is_points_gift', val)
      }
    },
    // 积分抵扣
    points_discount: {
      get() {
        return this.is_points_discount
      },
      set(val) {
        this.$emit('update:is_points_discount', val)
      }
    },
    // 会员折扣
    enable_grade: {
      get() {
        return this.is_enable_grade
      },
      set(val) {
        this.$emit('update:is_enable_grade', val)
      }
    },
    // 会员折扣配置
    alone_grade: {
      get() {
        return this.is_alone_grade
      },
      set(val) {
        this.$emit('update:is_alone_grade', val)
      }
    },
    // 单独折扣设置
    grade_equity: {
      get() {
        return this.level
      },
      set(val) {
        this.$emit('update:level', val)
      }
    }
  },
  created() {},
  mounted() {},

  methods: {
    refreshService() {
      this.$refs.service.getList()
    },
    addService() {
      let link = this.$router.resolve({ name: `GoodsServiceList` })
      window.open(link.href, '_blank')
    },
    updateImgs() {
      this.$refs.upload.bgList = this.image_urls.map((item) => {
        return {
          name: '',
          url: item
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper /deep/ {
  .tip {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 25px;
  }
  .el-divider--horizontal {
    margin: 30px 0;
  }
}
</style>
