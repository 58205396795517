<template>
  <div class="container">
    <template v-if="uploadConf.qiniu_upload">
      <el-upload
        :action="uploadConf.qiniu_upload"
        :data="uploadConf"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :on-success="handleAvatarSuccess"
        :on-error="handleError"
        :before-upload="beforeAvatarUpload"
        :file-list="bgList"
        :limit="limit"
      >
        <i class="el-icon-plus"></i>
        <div slot="tip" class="el-upload__tip">{{ tip }}</div>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </template>
  </div>
</template>

<script>
import { localget, localset } from '@/utils/localStorage'
import { getQiniuToken } from './api'
import { nanoid } from 'nanoid'
export default {
  name: 'UploaderFile',
  data() {
    return {
      uploadType: '',
      uploadConf: {},
      dialogImageUrl: '',
      dialogVisible: false,
      bgList: [],
      first: true
    }
  },

  props: {
    imgs: {
      type: Array,
      default: () => []
    },
    limit: {
      type: [String, Number],
      default: 10
    },
    tip: {
      type: String,
      default: ''
    }
  },
  watch: {
    imgs: {
      handler(val) {
        if (this.first) {
          this.first = false
          this.bgList = this.imgs.map((item) => {
            return {
              name: '',
              url: item
            }
          })
        }
      }
    }
  },

  mounted() {
    const config = localget('qiniuConfig')
    if (config) {
      this.uploadConf = config
    } else {
      this.getUploadConfig()
    }
  },
  methods: {
    async getUploadConfig() {
      const res = await getQiniuToken()
      // this.uploadType = res.uploadType
      this.uploadConf = res
      localset('qiniuConfig', this.uploadConf, 1800)
    },

    beforeAvatarUpload: function (file) {
      this.getUploadConfig()
      let fileExtension = file.name.substring(file.name.lastIndexOf('.') + 1)
      this.uploadConf.key = nanoid() + '.' + fileExtension
      const isJPG = file.type === 'image/jpeg'
      const isPNG = file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isJPG && !isPNG) {
        this.$message.error('图片只能是 JPG/PNG 格式!')
        return false
      }
      if (!isLt2M) {
        this.$message.error('图片大小不能超过 10MB!')
        return false
      }
    },
    handleAvatarSuccess(res, file, fileList) {
      console.log(file, fileList)
      // const { name, uid } = file
      let url = this.uploadConf.domain + '/' + res.key
      this.bgList = fileList
      this.$emit('update:imgs', [...this.imgs, url])
    },
    handleError: function (res) {
      this.$message.error({
        message: '上传失败',
        duration: 2000,
        type: 'warning'
      })
    },
    handleRemove(file, fileList) {
      console.log(file, fileList)
      const { uid } = file
      // this.bgList = this.bgList.filter((item) => uid != item.uid)
      this.bgList = fileList
      // this.$emit('update:imgs', this.bgList)
      this.$emit(
        'update:imgs',
        fileList.map((item) => {
          if (item.response) {
            return this.uploadConf.domain + '/' + item.response.key
          } else {
            return item.url
          }
        })
      )
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    }
  }
}
</script>

<style scoped lang="scss">
.container /deep/ {
  .el-upload-list__item.is-ready,
  .el-upload-list__item.is-uploading {
    display: none !important;
  }
}
</style>
