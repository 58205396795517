import { http } from '@/http/axios.js'

// 列表
export function getListAPI(data) {
  return http({
    url: `/api/store/goods/list`,
    method: 'post',
    data
  })
}
// detail
export function getDetailAPI(id) {
  return http({
    url: `/api/store/goods/detail`,
    method: 'get',
    params: { id }
  })
}
// add
export function addAPI(data) {
  return http({
    url: `/api/store/goods/add`,
    method: 'post',
    data
  })
}
// edit
export function editAPI(data) {
  return http({
    url: `/api/store/goods/edit`,
    method: 'post',
    data
  })
}
// del
export function delAPI(id) {
  return http({
    url: `/api/store/goods/del`,
    method: 'post',
    data: { id }
  })
}

// vip等级列表
export function getVipLevelListAPI(params) {
  return http({
    url: `/api/store/user/grade/list`,
    method: 'get',
    params
  })
}
