<template>
  <div class="sku-list">
    <el-form-item label="商品规格：">
      <div class="goods-type">
        <el-tag :key="tag" v-for="tag in dynamicTags" closable :disable-transitions="false" @close="handleClose(tag)">
          {{ tag }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="inputVisible"
          v-model="inputValue"
          ref="saveTagInput"
          size="small"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm"
        >
        </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增规格</el-button>
      </div>
    </el-form-item>
    <el-form-item label="SKU列表：">
      <SkuForm ref="sku" :theme="2" :source-attribute="sourceAttribute" :structure="structure" :attribute.sync="attribute" :sku.sync="sku">
        <template #image_url="slotProps">
          <UploadImg :url.sync="slotProps.row.image_url"></UploadImg>
        </template>
      </SkuForm>
    </el-form-item>
  </div>
</template>

<script>
import SkuForm from 'vue-sku-form'
import UploadImg from '@/components/qiniu-uploader/uploader-img.vue'
export default {
  name: 'SkuList',
  components: {
    SkuForm,
    UploadImg
  },
  props: {
    spec_list: {
      type: Array,
      default: () => []
    },
    sku_list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      sourceAttribute: [
        // {
        //   name: '颜色',
        //   item: ['红色']
        // }
      ],
      structure: [
        {
          name: 'goods_price',
          type: 'input',
          label: '商品价格'
        },
        {
          name: 'line_price',
          type: 'input',
          label: '划线价格'
        },
        {
          name: 'stock_num',
          type: 'input',
          label: '库存数量'
        },
        {
          name: 'goods_weight',
          type: 'input',
          label: '商品重量（KG）'
        },
        {
          name: 'goods_sku_no',
          type: 'input',
          label: 'SKU编码'
        },
        {
          name: 'image_url',
          type: 'slot',

          label: '预览图'
        }
      ],
      attribute: [],
      sku: [],
      dynamicTags: [],
      inputVisible: false,
      inputValue: ''
    }
  },
  watch: {
    // attribute: {
    //   handler(newV, oldV) {
    //     this.$emit('update:spec_list', newV)
    //   }
    // },
    // sku: {
    //   handler(newV, oldV) {
    //     this.$emit('update:sku_list', newV)
    //   }
    // },
    spec_list: {
      // immediate: true,
      handler(newV, oldV) {
        this.dynamicTags = newV.map((item) => {
          return item.spec_name
        })
        this.sourceAttribute = newV.map((item) => {
          return {
            name: item.spec_name,
            item: item.value_list
          }
        })
        this.attribute = newV.map((item) => {
          return {
            name: item.spec_name,
            item: item.value_list
          }
        })
        this.init()
      }
    },
    sku_list: {
      // immediate: true,
      handler(newV, oldV) {
        this.sku = newV
        this.init()
      }
    }
  },
  mounted() {},

  methods: {
    handleClose(tag) {
      let attrIndex = this.dynamicTags.indexOf(tag)
      console.log(attrIndex)
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1)
      this.sourceAttribute = this.sourceAttribute.filter((item, i) => item.name != tag)
      this.attribute = this.attribute.filter((item, i) => item.name != tag)
      this.sku.forEach((item) => {
        item.sku = item.sku
          .split(';')
          .filter((item, i) => i != attrIndex)
          .join(';')
      })
      this.init()
    },

    showInput() {
      this.inputVisible = true
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus()
      })
    },

    handleInputConfirm() {
      let inputValue = this.inputValue
      if (inputValue) {
        let data = {
          name: inputValue,
          item: []
        }
        this.dynamicTags.push(inputValue)
        this.sourceAttribute.push(data)
        this.init()
      }
      this.inputVisible = false
      this.inputValue = ''
    },
    init() {
      this.$refs.sku.init()
    }
  }
}
</script>
<style lang="scss" scoped>
.sku-list {
  .goods-type {
    .el-tag {
      margin-right: 10px;
    }
    .button-new-tag {
      // margin-left: 10px;
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
    }
    .input-new-tag {
      width: 90px;
      margin-left: 10px;
      vertical-align: bottom;
    }
  }
}
</style>
<style lang="scss" scoped>
.sku-list /deep/ {
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 50px !important;
    height: 50px !important;
    line-height: 50px !important;
    text-align: center;
  }
  .avatar {
    width: 50px !important;
    height: 50px !important;
    display: block;
  }
}
</style>
