<template>
  <div class="wrapper">
    <el-form-item label="商品名称：" prop="goods_name" :rules="rules">
      <el-input style="width: 300px" v-model="name" placeholder="请输入" size="small"></el-input>
    </el-form-item>
    <el-form-item style="margin-bottom: 0" label="商品分类：" prop="category_ids" :rules="rules">
      <GoodsCategoryListMult ref="category" v-model="cate" width="300px"></GoodsCategoryListMult>
      <div>
        <el-link style="font-size: 12px; margin-right: 10px" type="primary" :underline="false" @click="addCategory"
          >去新增</el-link
        >
        <el-link style="font-size: 12px" type="primary" :underline="false" @click="refreshCategory">刷新</el-link>
      </div>
    </el-form-item>
    <el-form-item label="商品图片：" prop="image_urls" :rules="rules">
      <UploadImgs ref="upload" :imgs.sync="imgs" tip="建议尺寸：750*750像素, 最多上传10张"> </UploadImgs>
    </el-form-item>
    <!-- <el-form-item label="商品编码：">
      <el-input style="width: 300px" v-model="no" placeholder="请输入" size="small"></el-input>
    </el-form-item> -->
    <!-- <el-form-item style="margin-bottom: 5px" label="运费模板：" prop="delivery_id" :rules="rules">
      <DeliveryList ref="delivery" v-model="deliveryId" size="small" width="300px"></DeliveryList>
      <div style="line-height: 25px">
        <el-link
          style="font-size: 12px; margin-right: 10px; line-height: 25px"
          type="primary"
          :underline="false"
          @click="addDelivery"
          >去新增</el-link
        >
        <el-link style="font-size: 12px; line-height: 25px" type="primary" :underline="false" @click="refreshDelivery"
          >刷新</el-link
        >
      </div>
    </el-form-item> -->
    <el-form-item label="是否首页推荐：" prop="is_recommend" :rules="rules">
      <el-radio-group v-model="is_recommend_status">
        <el-radio :label="0">不推荐</el-radio>
        <el-radio :label="1">推荐</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="商品状态：" prop="status" :rules="rules">
      <el-radio-group v-model="goods_status">
        <el-radio :label="20">上架</el-radio>
        <el-radio :label="10">下架</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="排序：" prop="sort" :rules="rules">
      <el-input-number v-model="goods_sort" :step="1" :min="0" size="small" step-strictly></el-input-number>
      <div class="tip">数字越小越靠前</div>
    </el-form-item>
  </div>
</template>

<script>
import GoodsCategoryListMult from '@/views/goods/goods-category/select-goods-category-mult.vue'
import UploadImgs from '@/components/qiniu-uploader/uploader-imgs.vue'
import DeliveryList from '@/views/systemManage/delivery/delivery-list.vue'
export default {
  name: 'BaseInfo',
  components: { GoodsCategoryListMult, UploadImgs, DeliveryList },
  props: {
    goods_name: {
      type: String,
      default: ''
    },
    category_ids: {
      type: Array,
      default: () => []
    },
    image_urls: {
      type: Array,
      default: () => []
    },
    goods_no: {
      type: String,
      default: ''
    },
    delivery_id: {
      type: [String, Number],
      default: ''
    },
    status: {
      type: [String, Number],
      default: ''
    },
    is_recommend: {
      type: [String, Number],
      default: ''
    },
    sort: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }]
    }
  },
  computed: {
    name: {
      get() {
        return this.goods_name
      },
      set(val) {
        this.$emit('update:goods_name', val)
      }
    },
    cate: {
      get() {
        return this.category_ids
      },
      set(val) {
        this.$emit('update:category_ids', val)
      }
    },
    imgs: {
      get() {
        return this.image_urls
      },
      set(val) {
        this.$emit('update:image_urls', val)
      }
    },
    no: {
      get() {
        return this.goods_no
      },
      set(val) {
        this.$emit('update:goods_no', val)
      }
    },
    deliveryId: {
      get() {
        return this.delivery_id
      },
      set(val) {
        this.$emit('update:delivery_id', val)
      }
    },
    is_recommend_status: {
      get() {
        return this.is_recommend
      },
      set(val) {
        this.$emit('update:is_recommend', val)
      }
    },
    goods_status: {
      get() {
        return this.status
      },
      set(val) {
        this.$emit('update:status', val)
      }
    },
    goods_sort: {
      get() {
        return this.sort
      },
      set(val) {
        this.$emit('update:sort', val)
      }
    }
  },
  mounted() {},

  methods: {
    refreshCategory() {
      this.$refs.category.getTree()
    },
    addCategory() {
      let link = this.$router.resolve({ name: `GoodsCategoryList` })
      window.open(link.href, '_blank')
    },
    updateImgs() {
      this.$refs.upload.bgList = this.image_urls.map(item => {
        return {
          name: '',
          url: item
        }
      })
    },
    addDelivery() {
      let link = this.$router.resolve({ name: `DeliveryList` })
      window.open(link.href, '_blank')
    },
    refreshDelivery() {
      this.$refs.delivery.getList()
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  .tip {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 25px;
  }
}
</style>
