<template>
  <div class="wrapper">
    <admin-title :title="$route.meta.name"></admin-title>
    <div class="tabs">
      <el-tabs v-model="activeName">
        <el-tab-pane label="基本信息" name="base"></el-tab-pane>
        <el-tab-pane label="规格/库存" name="stock"></el-tab-pane>
        <el-tab-pane label="商品详情" name="detail"></el-tab-pane>
        <el-tab-pane label="更多设置" name="more"></el-tab-pane>
      </el-tabs>
    </div>

    <el-form ref="form" :model="formData" :rules="rulesObj" label-width="200px" @submit.native.prevent>
      <!-- 基本信息 -->
      <div v-show="activeName == 'base'">
        <BaseInfo
          ref="baseInfo"
          :is_recommend.sync="formData.is_recommend"
          :goods_name.sync="formData.goods_name"
          :category_ids.sync="formData.category_ids"
          :image_urls.sync="formData.image_urls"
          :goods_no.sync="formData.goods_no"
          :delivery_id.sync="formData.delivery_id"
          :status.sync="formData.status"
          :sort.sync="formData.sort"
        ></BaseInfo>
      </div>
      <!-- 规格、库存 -->
      <div v-show="activeName == 'stock'">
        <el-form-item label="规格类型：" :rules="rules">
          <el-radio-group v-model="formData.spec_type">
            <el-radio :label="10">单规格</el-radio>
            <!-- <el-radio :label="20">多规格</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <template v-if="formData.spec_type == 10">
          <el-form-item label="商品积分：" :rules="rules">
            <el-input-number v-model="formData.goods_price" size="small" :step="1" :min="1" step-strictly></el-input-number>
            <!-- <span style="margin-left: 10px">元</span> -->
            <!-- <div class="tip">商品的实际购买金额，最低0.01</div> -->
          </el-form-item>
          <!-- <el-form-item label="划线价格：">
            <el-input-number v-model="formData.line_price" size="small" :step="0.01" :min="0.01" step-strictly></el-input-number>
            <span style="margin-left: 10px">元</span>
            <div class="tip">划线价仅用于商品页展示</div>
          </el-form-item> -->
          <el-form-item label="当前库存量：" :rules="rules">
            <el-input-number v-model="formData.stock_num" size="small" :step="1" :min="0" step-strictly></el-input-number>
            <span style="margin-left: 10px">件</span>
            <div class="tip">商品的实际库存数量，为0时用户无法下单</div>
          </el-form-item>
          <!-- <el-form-item label="商品重量：" :rules="rules">
            <el-input-number v-model="formData.goods_weight" size="small" :step="0.01" :min="0" step-strictly></el-input-number>
            <span style="margin-left: 10px">千克（kg）</span>
            <div class="tip">商品的实际重量，用于计算运费</div>
          </el-form-item> -->
        </template>
        <SkuList v-show="formData.spec_type == 20" ref="skuList" :spec_list.sync="formData.spec_data.spec_list" :sku_list.sync="formData.spec_data.sku_list"></SkuList>
        <el-form-item label="库存计算方式：" :rules="rules">
          <el-radio-group v-model="formData.deduct_stock_type">
            <el-radio :label="10">下单减库存</el-radio>
            <!-- <el-radio :label="20">付款减库存</el-radio> -->
          </el-radio-group>
        </el-form-item>
      </div>
      <!-- 商品详情 -->
      <div v-show="activeName == 'detail'">
        <el-form-item style="width: 1000px" label="商品详情：" :rules="rules">
          <Editor ref="editor" :showBtn="false" :content.sync="formData.content"></Editor>
        </el-form-item>
      </div>
      <!-- 更多设置 -->
      <div v-show="activeName == 'more'">
        <MoreSetting
          :video_url.sync="formData.video_url"
          :video_cover_url.sync="formData.video_cover_url"
          :selling_point.sync="formData.selling_point"
          :sales_initial.sync="formData.sales_initial"
          :serviceIds.sync="formData.serviceIds"
          :is_points_gift.sync="formData.is_points_gift"
          :is_points_discount.sync="formData.is_points_discount"
          :is_enable_grade.sync="formData.is_enable_grade"
          :is_alone_grade.sync="formData.is_alone_grade"
          :level.sync="level_list"
        >
        </MoreSetting>
      </div>
      <!-- 提交 -->
      <el-form-item label="">
        <el-button type="primary" size="small" @click="submit">提 交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addAPI, editAPI, getDetailAPI, getVipLevelListAPI } from './api'
import SkuList from '@/components/sku/sku-list.vue'
import BaseInfo from './modules/base-info.vue'
import MoreSetting from './modules/more-setting.vue'
import Editor from '@/components/editor/index.vue'

export default {
  name: 'GoodsForm',
  components: { SkuList, BaseInfo, Editor, MoreSetting },
  data() {
    return {
      activeName: 'base',
      rules: [{ required: true, message: '该项不能为空', trigger: 'blur' }],
      formData: {
        // 基本信息
        goods_name: '',
        category_ids: [],
        image_urls: [],
        goods_no: '',
        delivery_id: '',
        status: 10, //10上架  20下架
        sort: 0,
        // 库存
        spec_data: {
          spec_list: [],
          sku_list: []
        },
        deduct_stock_type: 10, //10下单减库存 20付款减库存
        spec_type: 10, //10单规格 20多规格
        goods_price: '',
        line_price: '',
        is_recommend: 0,
        stock_num: 0,
        goods_weight: 0,
        // 详情
        content: '',
        // 更多设置
        video_url: '',
        video_cover_url: '',
        selling_point: '',
        serviceIds: [], //服务与承诺
        sales_initial: 0, //初始销量
        is_points_gift: 1, //积分赠送 1开启  0关闭
        is_points_discount: 1, //积分抵扣  1开启 0关闭
        is_enable_grade: 1, //是否开启会员折扣 1开启 0关闭
        is_alone_grade: 0, //会员折扣设置 1单独设置  0默认折扣
        alone_grade_equity: {} //单独设置的折扣
      },
      rulesObj: {},
      level_list: []
    }
  },
  computed: {},
  mounted() {
    this.getVipLevelList()

    // setTimeout(() => {
    //   this.formData.spec_data = {
    //     spec_list: [
    //       {
    //         spec_name: '颜色',
    //         value_list: ['绿色', '红色']
    //       },
    //       {
    //         spec_name: '尺寸',
    //         value_list: ['大杯', '超大杯']
    //       }
    //     ],
    //     sku_list: [
    //       {
    //         image_url: 'xxx.png',
    //         goods_price: 1,
    //         line_price: 2,
    //         stock_num: 11,
    //         goods_weight: 66,
    //         goods_sku_no: '111',
    //         sku: '绿色;大杯'
    //       },
    //       {
    //         image_url: 'bbb.png',
    //         goods_price: 3,
    //         line_price: 4,
    //         stock_num: 12,
    //         goods_weight: 77,
    //         goods_sku_no: '222',
    //         sku: '绿色;超大杯'
    //       },
    //       {
    //         image_url: 'eee.png',
    //         goods_price: 5,
    //         line_price: 6,
    //         stock_num: 13,
    //         goods_weight: 88,
    //         goods_sku_no: '333',
    //         sku: '红色;大杯'
    //       },
    //       {
    //         image_url: 'abc.png',
    //         goods_price: 7,
    //         line_price: 8,
    //         stock_num: 14,
    //         goods_weight: 99,
    //         goods_sku_no: '444',
    //         sku: '红色;超大杯'
    //       }
    //     ]
    //   }
    // }, 50)
  },

  methods: {
    submit() {
      const sku_list = this.$refs.skuList.sku
      const spec_list = this.$refs.skuList.attribute.map((item) => {
        return {
          spec_name: item.name,
          value_list: item.item
        }
      })
      let data = { ...this.formData }
      data.spec_data = { sku_list, spec_list }
      if (data.is_alone_grade == 1 && data.is_enable_grade == 1) {
        data.alone_grade_equity = {}
        this.level_list.forEach((item) => {
          data.alone_grade_equity[`grade_id:${item.id}`] = item.equity
        })
      }

      console.log(data)

      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            editAPI(data).then(() => {
              this.$message.success('编辑成功')
              this.$router.back()
            })
          } else {
            addAPI(data).then(() => {
              this.$message.success('新增成功')
              this.$router.back()
            })
          }
        }
      })
    },
    async getDetail() {
      this.formData = await getDetailAPI(this.$route.params.id)

      const spec_list = this.formData.spec_list
      const sku_list = this.formData.goods_skus

      const category_ids = this.formData.category_ids_rel.map((item) => item.category_id)
      const image_urls = this.formData.goods_images.map((item) => item.image_url)
      this.$set(this.formData, 'spec_data', { sku_list, spec_list })
      this.$set(this.formData, 'category_ids', category_ids)
      this.$set(this.formData, 'image_urls', image_urls)
      this.$set(this.formData, 'goods_price', this.formData.goods_price_min)
      this.$set(this.formData, 'stock_num', this.formData.stock_total)
      // 开启会员折扣并且单独设置折扣
      if (this.formData.is_enable_grade == 1 && this.formData.is_alone_grade == 1) {
        this.level_list.forEach((item) => {
          item.equity = this.formData.alone_grade_equity[`${item.id}`]
        })
      }
    },
    async getVipLevelList() {
      this.level_list = await getVipLevelListAPI({ status: 20 })
      if (this.$route.params.id) {
        this.getDetail()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.wrapper {
  .tabs /deep/ {
    .el-tabs {
      .el-tabs__item {
        padding: 0 40px;
        &:nth-child(2) {
          padding-left: 0 !important;
        }
      }
      .el-tabs__nav-wrap::after {
        height: 1px;
      }
    }
  }
  .tip {
    color: #999;
    font-size: 12px;
    line-height: 25px;
  }
}
</style>
